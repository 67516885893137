.#{$grid-class-prefix}grid {
  display: grid;
  grid-template-columns: repeat($grid-column-count, 1fr);
  grid-column-gap: $grid-column-gap;

  &--inline {
    display: inline-grid;
  }

  &--stretch-items {
    justify-items: stretch;
  }

  &--center-items {
    justify-items: center;
  }
}

.#{$grid-class-prefix}col {

  &--end {
    justify-self: end;
  }

  &--stretch {
    justify-self: stretch;
  }

  &--center {
    justify-self: center;
  }
}

@for $i from 1 through $grid-column-count {

  .#{$grid-class-prefix}col--#{$i} {
    grid-column-end: span $i;
  }
}

@for $i from 1 through $grid-column-count {

  @include respond-to(handheld) {
    .#{$grid-class-prefix}col--xs-#{$i} {
      grid-column-end: span $i;
    }
  }

  @include respond-to(small-screen) {
    .#{$grid-class-prefix}col--s-#{$i} {
      grid-column-end: span $i;
    }
  }
}