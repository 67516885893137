@charset "UTF-8";
@font-face {
  font-family: "Climate Ice 1";
  src: url("../fonts/climate-ice-1-0920-webfont.woff2") format("woff2"), url("../fonts/climate-ice-1-0920-webfont.woff") format("woff"), url("../fonts/climate-ice-1-0920.ttf") format("truetype");
}
html {
  font-family: "Climate Ice 1", sans-serif;
  font-size: 1.3em;
  line-height: 1.5em;
}

h1, h2, h3 {
  font-weight: normal;
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 0;
  margin-bottom: 0.375em;
}

p {
  margin: 0;
  margin-bottom: 1.5em;
}

.align--left {
  text-align: left;
}

.align--center {
  text-align: center;
}

.align--right {
  text-align: right;
}

a, .link, .link--2, .resident a, .resident .link, .resident .link--2 {
  position: relative;
  color: Brown;
  text-decoration: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  cursor: pointer;
}
a:visited, .link:visited, .link--2:visited {
  color: Brown;
}
a:hover, .link:hover, .link--2:hover {
  color: black;
  text-decoration: none;
}
a.link--active, .link.link--active, .link--active.link--2 {
  color: Brown !important;
}

.link--2, .resident a, .resident .link, .resident .link--2 {
  color: Green;
}
.link--2:visited, .resident a:visited, .resident .link:visited {
  color: Green;
}
.link--2:hover, .resident a:hover, .resident .link:hover {
  color: black;
  text-decoration: none;
}
.link--2.link--active, .resident a.link--active, .resident .link--active.link {
  color: Green !important;
}

.text--secondary, .signup-form, .residents__navigation, .navigation, figure figcaption {
  font-size: 0.75rem !important;
  line-height: 1.5em !important;
}

img, video {
  display: block;
  max-width: 100%;
}

figure {
  margin: 0;
  margin-bottom: 0.75em;
}
.main-carousel {
  position: relative;
  width: 100%;
  outline: none;
}
.main-carousel .carousel-cell {
  width: 100%;
}
.main-carousel .carousel-control,
.main-carousel .carousel-control {
  position: absolute;
  z-index: 2;
  top: 1.5em;
  cursor: pointer;
  font-size: 1.5em;
  color: Brown;
}
.main-carousel .carousel-control:hover,
.main-carousel .carousel-control:hover {
  color: black;
}
.main-carousel .carousel-control--prev {
  left: 0;
}
.main-carousel .carousel-control--next {
  right: 0;
}
.main-carousel .carousel-control--disabled {
  display: none;
}

ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  margin-bottom: 1.5em;
}
ul li:last-of-type {
  margin-bottom: 0;
}
ul.list--tight li {
  margin-bottom: 0;
}
ul.list--stars li, ul.list--dashes li, .resident ul li {
  position: relative;
  padding-left: 0.5em;
}
ul.list--stars li:before, ul.list--dashes li:before, .resident ul li:before {
  position: absolute;
  top: 0;
  left: 0;
}
ul.list--stars li:before {
  content: "*";
}
ul.list--dashes li:before, .resident ul li:before {
  content: "- ";
}

.columns {
  display: flex;
  flex-flow: row nowrap;
}
@media only screen and (max-width: 768px) {
  .columns--respond-to-small {
    flex-wrap: wrap;
  }
  .columns--respond-to-small > * {
    width: 100%;
  }
}
.columns--space-between {
  justify-content: space-between;
}

.column--one-two {
  width: 50%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2em;
}
.grid--inline {
  display: inline-grid;
}
.grid--stretch-items {
  justify-items: stretch;
}
.grid--center-items {
  justify-items: center;
}

.col--end {
  justify-self: end;
}
.col--stretch {
  justify-self: stretch;
}
.col--center {
  justify-self: center;
}

.col--1 {
  grid-column-end: span 1;
}

.col--2 {
  grid-column-end: span 2;
}

.col--3 {
  grid-column-end: span 3;
}

.col--4 {
  grid-column-end: span 4;
}

.col--5 {
  grid-column-end: span 5;
}

.col--6 {
  grid-column-end: span 6;
}

.col--7 {
  grid-column-end: span 7;
}

.col--8 {
  grid-column-end: span 8;
}

.col--9 {
  grid-column-end: span 9;
}

.col--10 {
  grid-column-end: span 10;
}

.col--11 {
  grid-column-end: span 11;
}

.col--12 {
  grid-column-end: span 12;
}

@media only screen and (max-width: 768px) {
  .col--xs-1 {
    grid-column-end: span 1;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-1 {
    grid-column-end: span 1;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-2 {
    grid-column-end: span 2;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-2 {
    grid-column-end: span 2;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-3 {
    grid-column-end: span 3;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-3 {
    grid-column-end: span 3;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-4 {
    grid-column-end: span 4;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-4 {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-5 {
    grid-column-end: span 5;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-5 {
    grid-column-end: span 5;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-6 {
    grid-column-end: span 6;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-6 {
    grid-column-end: span 6;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-7 {
    grid-column-end: span 7;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-7 {
    grid-column-end: span 7;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-8 {
    grid-column-end: span 8;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-8 {
    grid-column-end: span 8;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-9 {
    grid-column-end: span 9;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-9 {
    grid-column-end: span 9;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-10 {
    grid-column-end: span 10;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-10 {
    grid-column-end: span 10;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-11 {
    grid-column-end: span 11;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-11 {
    grid-column-end: span 11;
  }
}
@media only screen and (max-width: 768px) {
  .col--xs-12 {
    grid-column-end: span 12;
  }
}
@media only screen and (min-width: 769px) {
  .col--s-12 {
    grid-column-end: span 12;
  }
}
.js-expandable.js-expandable--contracted .js-expandable__btn:after {
  content: " (…)";
}
.js-expandable.js-expandable--contracted .js-expandable__content {
  display: none;
}
.js-expandable.js-expandable--expanded .js-expandable__content {
  display: block;
}
.js-expandable .js-expandable__content {
  overflow: hidden;
}

html {
  box-sizing: border-box;
}
html *, html *:before, html *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

@media only screen and (min-width: 769px) {
  header, main, footer {
    width: 75%;
  }
}

.sidebar {
  border-bottom: 2px solid black;
}
@media only screen and (min-width: 769px) {
  .sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 25%;
    height: 100vh;
    border-top: none;
    border-bottom: none;
    border-left: 2px solid black;
    overflow: scroll;
  }
}

.header h1, .header h2, .header h3, .header p, .header ul, .header form, .main h1, .main h2, .main h3, .main p, .main ul, .main form, .news h1, .news h2, .news h3, .news p, .news ul, .news form {
  padding-left: 0.3em;
  padding-right: 0.3em;
}

@media only screen and (min-width: 769px) {
  .header p, .header ul, .main p, .main ul {
    padding-left: 3rem;
  }
}

footer {
  margin-top: 3rem;
}
footer h1, footer h2, footer h3, footer p {
  padding-left: 1.5rem;
}
@media only screen and (min-width: 769px) {
  footer h1, footer h2, footer h3, footer p {
    padding-left: 3rem;
  }
}
footer p.credits {
  margin-bottom: 0;
}
footer p.credits span {
  font-size: 0.8em;
  line-height: 1.5em;
  background: black;
  border-bottom: 2px solid black;
  color: white !important;
}
footer p.credits span a {
  color: yellow;
}
footer p.credits span a:hover {
  color: black;
}

@media only screen and (max-width: 768px) {
  .header h1 {
    margin-bottom: 0;
  }
}
.header h1 {
  margin-bottom: 0;
}

@media only screen and (min-width: 769px) {
  .header, .navigation {
    width: 75%;
  }
}

.navigation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background: white;
  border-bottom: 2px solid black;
}
@media only screen and (min-width: 769px) {
  .navigation {
    font-size: 1em;
    line-height: 1.5em;
  }
}
.navigation.js-sticky--fixed {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
}
@media only screen and (min-width: 769px) {
  .navigation.js-sticky--fixed {
    width: 75%;
  }
}
.navigation__locale {
  width: 80px;
  flex-shrink: 0;
  align-self: flex-end;
}
.navigation__sections {
  flex-shrink: 1;
}
.navigation a {
  display: inline-block;
  text-decoration: none;
  word-break: normal;
  word-wrap: normal;
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
  color: black;
}
.navigation a:hover {
  color: black;
}
.navigation a:after {
  content: " /";
}
.navigation a:last-of-type:after {
  content: "";
}
.navigation a.link--active:after, .navigation a:hover:after {
  color: black;
}
.navigation a.navigation__locale {
  width: 100px;
}
.navigation a.navigation__locale__link--current {
  color: black;
}

.sticky {
  -webkit-position: sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.section {
  border-bottom: 2px solid black;
  padding-bottom: 0.75em;
}
.section:last-of-type {
  margin-bottom: 0;
}

.section p {
  text-indent: 3rem;
  margin-bottom: 0;
}
.section figure, .section ul, .section ol {
  padding-left: 3rem;
}
.section figure {
  padding-right: 0.3em;
}
@media only screen and (max-width: 768px) {
  .section figure {
    padding-left: 0.3em;
  }
}
@media only screen and (min-width: 769px) {
  .section ul, .section ol {
    padding-left: 6rem;
  }
}
.section figcaption p {
  text-indent: 0;
}

.residents__navigation {
  text-align: right;
  padding: 0.3em;
  margin-bottom: 1.5em;
}
.residents__navigation a {
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  .residents__navigation a {
    display: block;
  }
}

.resident h1.resident__title {
  position: relative;
  padding-left: 3rem;
}
.resident h1.resident__title:before {
  content: "•";
  position: absolute;
  top: 0;
  left: 2.1rem;
}
.resident.js-expandable--expanded .js-expandable__btn {
  color: black !important;
}
.resident__content {
  margin-bottom: 1.5em;
}
.news__item p {
  margin: 0;
}
.news .js-expandable--contracted .js-expandable__content {
  display: block;
}
@media only screen and (max-width: 768px) {
  .news .js-expandable--contracted .js-expandable__content .news__item {
    display: none;
  }
  .news .js-expandable--contracted .js-expandable__content .news__item:nth-child(-n+1) {
    display: block;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 769px) {
  .news .js-expandable--contracted .js-expandable__content .news__item {
    display: block;
  }
}
.news .js-expandable__btn {
  display: none;
}
@media only screen and (max-width: 768px) {
  .news .js-expandable__btn {
    display: block;
  }
}
.news .js-expandable--expanded .js-expandable__btn {
  display: none;
}

.signup-form:hover {
  background: yellow;
}
.signup-form__control {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0.375em;
}
.signup-form__control > *:first-child {
  flex-shrink: 0;
  padding-right: 0.25em;
}
.signup-form__control > *:last-child {
  flex-grow: 1;
}
.signup-form__submit {
  text-align: right;
}
.signup-form input {
  font-family: "Climate Ice 1", sans-serif;
  font-size: 0.85em;
  line-height: 0.85em;
  color: Brown;
  background: none;
  border: none;
  border-bottom: 1px solid black;
  outline: 0;
}
.signup-form input::selection, .signup-form input::-moz-selection {
  background: white;
}
.signup-form input[type=submit] {
  font-size: 1.2em;
  height: 1.5em;
  text-align: right;
  border: none;
  background: none;
  cursor: pointer;
}
.signup-form input[type=submit]:hover {
  color: white;
  background: black;
}