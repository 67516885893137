html {
  font-family: 'Climate Ice 1', sans-serif;
  font-size: 1.3em;
  line-height: $line-height;
}

h1, h2, h3 {
  font-weight: normal;
  font-size: 1.5em;
  line-height: $line-height;
  margin: 0;
  margin-bottom: .25 * $line-height;
}

p {
  margin: 0;
  margin-bottom: $line-height;
}

.align--left {
  text-align: left;
}

.align--center {
  text-align: center;
}

.align--right {
  text-align: right;
}

a, .link {
  position: relative;
  color: $link-color;
  text-decoration: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  @include prefix((hyphens: auto), ms moz webkit);
  cursor: pointer;

  &:visited {
    color: $link-color;
  }

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }

  &.link--active {
    color: $link-color !important;
  }
}

.link--2 {
  @extend .link;
  color: $link-color-2;

  &:visited {
    color: $link-color-2;
  }

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }

  &.link--active {
    color: $link-color-2 !important;
  }
}

.text--secondary {
  font-size: .75rem !important;
  line-height: $line-height !important;
}
