.news {

  &__item {
    p {
      margin: 0;
    }
  }

  .js-expandable--contracted .js-expandable__content {
    display: block;
  }

  @include respond-to(handheld) {

    .js-expandable--contracted .js-expandable__content .news__item {
      display: none;

      &:nth-child(-n+1) {
        display: block;
        margin-bottom: 0;
      }
    }
  }

  @include respond-to(small-screen) {

    .js-expandable--contracted .js-expandable__content .news__item {
      display: block;
    }
  }

  .js-expandable__btn {
    display: none;

    @include respond-to(handheld) {
      display: block;
    }
  }

  .js-expandable--expanded .js-expandable__btn {
    display: none;
  }
}

.signup-form {
  @extend .text--secondary;

  &:hover {
    background: yellow;
  }

  &__control {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: .25 * $line-height;

    > *:first-child {
      flex-shrink: 0;
      padding-right: .25em;
    }

    > *:last-child {
      flex-grow: 1;
    }
  }

  &__submit {
    text-align: right;
  }

  input {
    font-family: 'Climate Ice 1', sans-serif;
    font-size: .85em;
    line-height: .85em;
    color: $link-color;
    background: none;
    border: none;
    border-bottom: 1px solid black;
    outline: 0;

    &::selection, &::-moz-selection {
      background: white;
    }
  }

  input[type="submit"] {
    font-size: 1.2em;
    height: $line-height;
    text-align: right;
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
      color: white;
      background: black;
    }
  }
}
