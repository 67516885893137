.js-expandable {

  &.js-expandable--contracted {

    .js-expandable__btn:after {
      content: ' (…)';
    }

    .js-expandable__content {
      display: none;
    }
  }

  &.js-expandable--expanded {

    .js-expandable__content {
      display: block;
    }
  }

  .js-expandable__content {
    overflow: hidden;
  }
}