.section {
  border-bottom: 2px solid black;
  padding-bottom: .5 * $line-height;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.section {

  // h1, h2, h3, p {
  //   text-indent: $page-indent;
  // }

  p {
    text-indent: $page-indent;
    margin-bottom: 0;
  }

  figure, ul, ol {
    padding-left: $page-indent;
  }

  figure {
    padding-right: $page-padding;

    @include respond-to(handheld) {
      padding-left: $page-padding;
    }
  }

  ul, ol {

    @include respond-to(small-screen) {
      padding-left: 2 * $page-indent;
    }
  }

  figcaption p {
    text-indent: 0;
  }
}

.residents {

  &__navigation {
    @extend .text--secondary;
    text-align: right;
    padding: $page-padding;
    margin-bottom: $line-height;

    a {
      display: inline-block;
    }

    @include respond-to(handheld) {
      a {
        display: block;
      }
    }
  }
}

.resident {

  h1.resident__title {
    position: relative;
    padding-left: $page-indent;

    &:before {
      content: '•';
      position: absolute;
      top: 0;
      left: .7 * $page-indent;
    }    
  }

  &.js-expandable--expanded .js-expandable__btn {
    color: black !important;
  }

  &__content {
    margin-bottom: $line-height;
  }

  ul {
    @extend .list--dashes;
  }

  a, .link {
    @extend .link--2;
  }
}
