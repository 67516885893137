.columns {
  display: flex;
  flex-flow: row nowrap;

  &--respond-to-small {
    @include respond-to(handheld) {
      flex-wrap: wrap;

      & > * {
        width: 100%;
      }
    }
  }

  &--space-between {
    justify-content: space-between;
  }
}

.column {

  &--one-two {
    width: 50%;
  }
}
