html {
  box-sizing: border-box;

  *, *:before, *:after {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
}

header, main, footer {

  @include respond-to(small-screen) {
    width: 75%;
  }
}

.sidebar {
  border-bottom: 2px solid black;

  @include respond-to(small-screen) {
    position: fixed;
    top: 0;
    right: 0;
    width: 25%;
    height: 100vh;
    border-top: none;
    border-bottom: none;
    border-left: 2px solid black;
    overflow: scroll;
  }
}

.header, .main, .news {

  h1, h2, h3, p, ul, form {
    padding-left: $page-padding;
    padding-right: $page-padding;
  }
}

.header, .main {

  p, ul {

    @include respond-to(small-screen) {
      padding-left: $page-indent;
    }
  }
}

footer {
  margin-top: 3rem;

  h1, h2, h3, p {
    padding-left: .5 * $page-indent;

    @include respond-to(small-screen) {
      padding-left: $page-indent;
    }
  }

  p.credits {
    margin-bottom: 0;

    span {
      font-size: .8em;
      line-height: $line-height;
      background: black;
      border-bottom: 2px solid black;
      color: white !important;

      a {
        color: yellow;

        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }
}
