img, video {
  display: block;
  max-width: 100%;
}

figure {
  margin: 0;
  margin-bottom: .5 * $line-height;

  figcaption {
    @extend .text--secondary;
  }
}

.main-carousel {
  position: relative;
  width: 100%;
  outline: none;

  .carousel-cell {
    width: 100%;
  }

  .carousel-control,
  .carousel-control {
    position: absolute;
    z-index: 2;
    top: $line-height;
    cursor: pointer;
    font-size: 1.5em;
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  .carousel-control--prev {
    left: 0;
  }

  .carousel-control--next {
    right: 0;
  }

  .carousel-control--disabled {
    display: none;
  }
}
