ul {
  list-style-type: none;
  padding: 0;

  li {
    margin-bottom: $line-height;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.list--tight li {
    margin-bottom: 0;
  }

  &.list--stars li,
  &.list--dashes li {
    position: relative;
    padding-left: .5em;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.list--stars li {
    &:before {
      content: "*";
    }
  }

  &.list--dashes li {
    &:before {
      content: "- ";
    }
  }
}