.header {
  @include respond-to(handheld) {
    h1 {
      margin-bottom: 0;
    }
  }

  h1 {
    margin-bottom: 0;
  }
}

.header, .navigation {
  @include respond-to(small-screen) {
    width: 75%;
  }
}

.navigation {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background: white;
  border-bottom: 2px solid black;
  @extend .text--secondary;

  @include respond-to(small-screen) {
    font-size: 1em;
    line-height: $line-height;
  }

  &.js-sticky--fixed {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;

    @include respond-to(small-screen) {
      width: 75%;
    }
  }

  &__locale {
    width: 80px;
    flex-shrink: 0;
    align-self: flex-end;
  }

  &__sections {
    flex-shrink: 1;
  }

  a {
    display: inline-block;
    text-decoration: none;
    word-break: normal;
    word-wrap: normal;
    @include prefix((hyphens: none), ms moz webkit);
    color: black;

    &:hover {
      color: $link-hover-color;
    }

    &:after {
      content: ' /';
    }

    &:last-of-type:after {
      content: '';
    }

    &.link--active:after,
    &:hover:after {
      color: black;
    }

    &.navigation__locale {
      width: 100px;

      &__link--current {
        // text-transform: uppercase;
        color: $link-hover-color;
      }
    }
  }
}

.sticky {
  @include prefix((position: sticky), webkit);
  top: 0;
  z-index: 2;
}
